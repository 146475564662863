@import '../../assets/base.css';

.display-modules {
    padding: 0 10px;
    /* padding-bottom: 120px; */
    /* padding-top: 96px; */
}

.pt-156 {
    padding-top: 156px;
}
.pt-50 {
    padding-top: 50px;
}
.pb-50 {
    padding-bottom: 50px;
}
.pb-40 {
    padding-bottom: 40px;
}

.pb-24 {
    padding-bottom: 24px;
}
.module-detail .module-content {
    /* min-width: 325px; */
}
.module-content .label {
    margin-bottom: 24px;
    font-weight: 700;
}
.module-content .describe {
    line-height: 24px;
    color: rgba(31,34,40,0.8);
    text-align: center;
}
.check-detail {
    color: #0E60FF;
    margin-top: 24px;
    width: 80px;
    justify-content: space-between;
    height: 24px;
}
.arrow-right {
    width: 20px;
    height: 20px;
}
.module-pic {
    margin-top: 30px;
    width: 100%;
    /* width: 712px; */
    /* height: 400px; */
}
.row-pic {
    width: 548px;
}
.row-text {
    width: 420px;
}
.row-subtitle {
    line-height: 36px;
    color: #2B2B2B;
}
.describe-row {
    color: #535353;
    line-height: 30px;
}