@import '../../assets/base.css';

.ant-back-top {
    right: 72px !important;
    bottom: 96px !important;
}
.ant-back-top:hover {
    color: #0E60FF;
}
.back-top {
    justify-content: center;
    height: 72px;
    width: 72px;
    border-radius: 4px;
    border: 1px solid rgba(255,255,255,1);
    background: linear-gradient(180deg, rgba(243,245,248,1) 0%, rgba(255,255,255,1) 100%);
    box-shadow: 8px 8px 20px 0 rgba(55,99,170,0.1);
}
.icon-top {
    margin-bottom: 5px;
}