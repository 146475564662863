@import '../../../assets/base.css';

.bg-list {
    margin-bottom: 80px;
    background: linear-gradient(180deg, rgba(245,247,250,0) 0%, rgba(245,247,250,1) 100%);
}
.new-item {
    padding: 24px 40px;
}
.divider {
    width: 924px;
    height: 1px;
    margin: 0 auto;
    background-color: rgba(31,34,40,0.1);
}
.left-box {
    /* width: 840px; */
}
.right-img {
    width: 214px;
    height: 160px;
}
.news .news-title {
    margin-bottom: 16px;
}
.news-title:hover {
    color: #0E60FF;
}
.news .news-content-list {
    line-height: 24px;
}
.news.time-box {
    margin-top: 16px;
}

.ant-spin {
    width: 100%;
}
.spin-style {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 52px;
}
