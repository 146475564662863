@import '../../assets/base.css';

.supply_chain .pain_card {
    width: 238px;
    height: 280px;
    box-shadow: 0px 2px 10px 7px rgba(0,38,92,0.08);
    border-radius: 10px;
    padding: 36px;
    z-index: 2;
}
.supply_chain .pain_card .card_icon {
    width: 60px;
    height: 60px;
    margin-bottom: 24px;
}
.supply_chain .pain_card .card_title {
    font-size: 20px;
    font-weight: bold;
    color: #2B2B2B;
    line-height: 28px;
    margin-bottom: 18px;
}
.supply_chain .pain_card .card_desc {
    color: #7E7E7E;
    line-height: 26px;
    font-size: 14px;
}
.solution-title {
    width: 422px;
    font-weight: bold;
    color: #2B2B2B;
    text-align: center;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 12px;
}
.supply_chain .transfer-box {
    width: 422px;
    height: 310px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 26px;
    padding: 16px;
}
.transfer_img {
    width: 160px;
    height: 188px;
}
.supply_chain .before-box {
    background: linear-gradient(90deg, rgba(119, 123, 147, 0.1), rgba(165, 168, 185, 0.1));
}
.supply_chain .after-box {
    background: linear-gradient(270deg, rgba(90, 176, 255, 0.1), rgba(0, 44, 249, 0.1));
}
.supply_chain .transfer-item {
    width: 390px;
    height: 278px;
    border-radius: 8px;
    padding: 10px 36px 28px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
}
.supply_chain .before-item {
    background: rgba(209, 213, 223, 0.6);
    color: #535353;
}
.supply_chain .after-item {
    background: linear-gradient(270deg, rgba(33, 148, 255, 1), rgba(49, 86, 255, 1));
    color: #fff;
}
.supply_chain  .tranfer-text {
    margin-top: 12px;
}
.step_number {
    font-size: 130px;
    line-height: 120px;
    color: rgba(40, 40, 40, 0.2);
    font-family: Arial-BoldMT, Arial;
    position: absolute;
}
.step_title {
    font-weight: bold;
    color: #2B2B2B;
    line-height: 40px;
    font-size: 32px;
    text-align: center;
    padding-top: 50px;
}
.step_img {
    width: 100%;
}
.step_desc {
    font-size: 16px;
    color: #535353;
    line-height: 30px;
    padding: 0 24px;
}
.supply_chain .fix-block {
    position: absolute;
    top: 90px;
    width: 100%;
}
.supply_chain .produce-bg {
    background-image: url("../../assets/produce-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 142px;
}
.slogen-style {
    width: 480px;
    height: 72px;
    background: linear-gradient(315deg, #2194FF 0%, #3156FF 100%);
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.24);
    border-radius: 36px;
    color: #fff;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    padding-left: 55px;
    margin-bottom: 20px;
}
.dot-style {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #fff;
    margin-right: 9px;
}
.value-text {
    color: #7E7E7E;
    line-height: 22px;
}
.w195 {
    width: 195px;
}
.w594 {
    width: 594px;
}
.w292 {
    width: 292px;
}
.grid_style {
    margin-top: 40px;
}
.grid_title {
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    color: #fff;
    background: linear-gradient(315deg, #2194FF 0%, #3156FF 100%);
    border-radius: 4px;
    backdrop-filter: blur(3.691283086727784px);
    padding-left: 22px;
}
.grid_title:first-child {
    background: linear-gradient(90deg, #777B93 0%, #A5A8B9 100%);
}
.grid_value {
    background: rgba(255,255,255,0.3);
    border-radius: 4px;
    border: 1px solid;
    border-image: linear-gradient(90deg, rgba(119, 123, 147, 0.5), rgba(165, 168, 185, 0.5)) 1 1;
    backdrop-filter: blur(5px);
    padding: 16px 22px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
}
.goal_title {
    color: #2B2B2B;
    line-height: 32px;
}
.middle-text {
    color: #535353;
    line-height: 32px;
    font-size: 18px;
}
.profit-title {
    font-weight: bold;
    color: #535353;
    line-height: 32px;
    font-size: 18px;
}
.profit-desc {
    color: #7E7E7E;
    line-height: 22px;
    font-size: 14px;
}
.supply_chain .border-bottom {
    border-bottom: 1px solid;
    border-image: linear-gradient(90deg, rgba(119, 123, 147, 0.5), rgba(165, 168, 185, 0.5)) 1 1;
}
.supply_chain .tool-box {
    padding-bottom: 46px;
    width: 340px;
    border-right: 1px solid;
    border-image: linear-gradient(90deg, rgba(119, 123, 147, 0.5), rgba(165, 168, 185, 0.5)) 1 1;
    padding-top: 16px;
}
.supply_chain .tool-box:last-child {
    border-right: none;
}
.background_img {
    width: 580px;
}
.background_text {
    width: 380px;
}
.background_title {
    font-size: 22px;
    font-weight: bold;
    color: #2B2B2B;
    line-height: 30px;
    margin-bottom: 6px;
}
.background_desc {
    font-size: 18px;
    color: rgba(40, 40, 40, 0.9);
    line-height: 26px;
}
.background_icon {
    width: 11px;
    margin-right: 10px;
}
.tips {
    margin-top: 6px;
    color: #7E7E7E;
    line-height: 22px;
    font-size: 14px;
}
.core_bg {
    position: absolute;
    top: 911px;
    width: 100%;
    height: 950px;
}
.quotation {
    width: 55px;
}
.quotation_slogen {
    width: 857px;
    font-weight: bold;
    color: #2B2B2B;
    line-height: 40px;
    font-size: 28px;
    text-align: center;
}
.short_bar {
    width: 8px;
    height: 32px;
    background: linear-gradient(315deg, #2194FF 0%, #3156FF 100%);
    border-radius: 4px;
    margin-right: 20px;
}
.product_value_title {
    font-weight: bold;
    color: #2B2B2B;
    line-height: 36px;
    font-size: 28px;
}
.float_icon {
    width: 12px;
    margin-left: 8px;
}
.grid_icon_style {
    flex-direction: row;
    justify-content: start;
    align-items: center;
}
.out_box {
    width: 144px;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px 6px rgba(14,59,91,0.06);
    border-radius: 6px;
    padding: 4px;
}
.inner_box {
    width: 136px;
    height: 52px;
    background: #BFD4FF;
    border-radius: 4px;
    font-weight: 500;
    color: #535353;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 6px 25px;
}
.process_arrow {
    width: 30px;
}