@import '../../assets/base.css';
.produce-content {
    padding-top: 100px;
}
.produce-supply .point-card {
    width: 238px;
    height: 270px;
    box-shadow: 0px 2px 10px 7px rgba(0,38,92,0.08);
    border-radius: 10px;
    padding: 36px;
}
.produce-supply .point-card .card-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 14px;
}
.produce-supply .point-card .card-title {
    font-size: 20px;
    font-weight: bold;
    color: #2B2B2B;
    line-height: 28px;
    margin-bottom: 18px;
}
.produce-supply .point-card .card-desc {
    color: #7E7E7E;
    line-height: 26px;
    font-size: 14px;
}
.solution-title {
    width: 422px;
    font-weight: bold;
    color: #2B2B2B;
    text-align: center;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 12px;
}
.produce-supply .transfer-box {
    width: 422px;
    height: 570px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 26px;
    padding: 16px;
}
.produce-supply .transfer-img {
    width: 160px;
    height: 351px;
}
.produce-supply .before-box {
    background: linear-gradient(90deg, rgba(119, 123, 147, 0.1), rgba(165, 168, 185, 0.1));
}
.produce-supply .after-box {
    background: linear-gradient(270deg, rgba(90, 176, 255, 0.1), rgba(0, 44, 249, 0.1));
}
.produce-supply .transfer-item {
    width: 390px;
    height: 122px;
    border-radius: 8px;
    padding: 10px 40px 28px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.produce-supply .transfer-item:first-child {
    height: 148px;
}
.produce-supply .before-item {
    background: rgba(209, 213, 223, 0.6);
    color: #535353;
}
.produce-supply .after-item {
    background: linear-gradient(270deg, rgba(33, 148, 255, 1), rgba(49, 86, 255, 1));
    color: #fff;
}
.produce-supply .tranfer-text {
    margin-top: 12px;
}
.step-number {
    font-size: 120px;
    line-height: 120px;
    color: rgba(40, 40, 40, 0.2);
    font-family: Arial-BoldMT, Arial;
}
.produce-supply .fix-block {
    position: absolute;
    top: 100px;
    width: 100%;
}
.produce-supply .produce-bg {
    background-image: url("../../assets/produce-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 53px;
    padding-top: 100px;
}
.value-text {
    color: #7E7E7E;
    line-height: 22px;
}
.w-170 {
    width: 170px;
}
.w-184 {
    width: 184px;
}
.w-634 {
    width: 634px;
}
.grid-title {
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    color: #fff;
    background: linear-gradient(315deg, #2194FF 0%, #3156FF 100%);
    border-radius: 4px;
    backdrop-filter: blur(3.691283086727784px);
    padding-left: 22px;
}
.grid-title:first-child {
    background: #9497AA;
}
.grid-value {
    background: rgba(255,255,255,0.3);
    border-radius: 4px;
    border: 1px solid;
    border-image: linear-gradient(90deg, rgba(119, 123, 147, 0.5), rgba(165, 168, 185, 0.5)) 1 1;
    backdrop-filter: blur(5px);
    padding: 16px 22px;
    height: 118px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.goal-title {
    color: #2B2B2B;
    line-height: 32px;
}
.middle-card {
    height: 496px;
    background: rgba(255,255,255,0.3);
    border: 1px solid;
    border-image: linear-gradient(90deg, rgba(119, 123, 147, 0.5), rgba(165, 168, 185, 0.5)) 1 1;
    border-radius: 4px;
    backdrop-filter: blur(5px);
}
.middle-text {
    color: #272727;
    line-height: 82px;
}

.profit-title {
    font-weight: bold;
    color: #535353;
    line-height: 32px;
    font-size: 18px;
}
.profit-desc {
    color: #7E7E7E;
    line-height: 22px;
    font-size: 14px;
}
.arrow_style {
    width: 62px;
}
.mb118 {
    margin-bottom: 118px;
}
.advanced_item {
    width: 268px;
    height: 100px;
    background: linear-gradient(315deg, rgba(33, 148, 255, 0.1), rgba(49, 86, 255, 0.1));
    border-radius: 16px;
    margin-bottom: 12px;
    padding-left: 45px;
}
.advanced_item:last-child {
    margin-bottom: 0;
}
.advanced_img {
    width: 13px;
}
.blue_text {
    color: #0019FE;
    line-height: 36px;
}
.mb60 {
    margin-bottom: 60px;
}