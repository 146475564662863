@import "../../assets/base.css";

.contact {
    position: fixed;
    right: 40px;
    bottom: 386px;
    z-index: 9999;
}
.contact-us {
    width: 72px;
    height: 161px;
    border: 1px solid rgba(255,255,255,1);
    background: linear-gradient(180deg, rgba(243,245,248,1) 0%, rgba(255,255,255,1) 100%);
    box-shadow: 8px 8px 20px 0 rgba(55,99,170,0.1);
    border-radius: 4px;
    padding: 14px 0;

}
.divide {
    width: 20px;
    height: 1px;
    background: rgba(31,34,40,0.2);
    margin: 22px 0;
}
.contact-img {
    width: 24px;
    height: 24px;
}
.pop-card {
    width: 160px;
    padding: 18px;
    padding-top: 14px;
    border-radius: 4px;
    border: 1px solid rgba(255,255,255,1);
    background: linear-gradient(180deg, rgba(243,245,248,1) 0%, rgba(255,255,255,1) 100%);
    box-shadow: 8px 8px 20px 0 rgba(55,99,170,0.1);
    position: absolute;
    right: 86px;
    line-height: 16px;
    color: #0E60FF;
}
.pop-card .phone {
    margin-bottom: 6px;
}
.bottom-card {
    bottom: 0;
    padding-right: 0;
}
.hover-font {
    color: #0E60FF;
}