.flex-alc-jcs {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex-alc-jcc {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex {
    display: flex;
}
.flex-row {
    display: flex;
    flex-direction: row;
}
.flex-jcb {
    display: flex;
    justify-content: space-between;
}
.flex-alc {
    display: flex;
    align-items: center;
}
.flex-column-alc {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.flex-column-alc-jcc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.flex-jcc {
    display: flex;
    justify-content: center;
}
.flex-column {
    display: flex;
    flex-direction: column;
}
.flex-reverse {
    flex-direction: row-reverse;
}
.flex-jce {
    display: flex;
    justify-content: flex-end;
}
.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}
.fw-700 {
    font-weight: 700;
}
.fw-500 {
    font-weight: 500;
}
.pb-120 {
    padding-bottom: 120px;
}
.pb-100 {
    padding-bottom: 100px;
}
.pb-40 {
    padding-bottom: 40px;
}
.pb-30 {
    padding-bottom: 30px;
}
.pb-20 {
    padding-bottom: 20px;
}
.pb-133 {
    padding-bottom: 133px;
}
.plr-10 {
    padding-left: 10px;
    padding-right: 10px;
}
.pt-120 {
    padding-top: 120px;
}
.pt-100 {
    padding-top: 100px;
}
.pt-96 {
    padding-top: 96px;
}
.pt-64 {
    padding-top: 64px;
}
.pt-50 {
    padding-top: 50px;
}
.pt-30 {
    padding-top: 30px;
}
.white-bg {
    background-color: #fff;
}
.ml-32 {
    margin-left: 32px;
}
.ml-18 {
    margin-left: 18px;
}
.ml-15 {
    margin-left: 15px;
}
.ml-14 {
    margin-left: 14px;
}
.ml-8 {
    margin-left: 8px;
}
.mr-28 {
    margin-right: 28px;
}
.mt-100 {
    margin-top: 100px;
}
.mt-96 {
    margin-top: 96px;
}
.mt-64 {
    margin-top: 64px;
}
.mt-60 {
    margin-top: 60px;
}
.mt-56 {
    margin-top: 56px;
}
.mt-50 {
    margin-top: 50px;
}
.mt-48 {
    margin-top: 48px;
}
.mt-46 {
    margin-top: 46px;
}
.mt-40 {
    margin-top: 40px;
}
.mt-36 {
    margin-top: 36px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-28 {
    margin-top: 28px;
}
.mt-24 {
    margin-top: 24px;
}
.mt-22 {
    margin-top: 22px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-16 {
    margin-top: 16px;
}
.mt-18 {
    margin-top: 18px;
}
.mt-12 {
    margin-top: 12px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-8 {
    margin-top: 8px;
}
.mt-6 {
    margin-top: 6px;
}
.mb-120 {
    margin-bottom: 120px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-24 {
    margin-bottom: 24px;
}
.mb-22 {
    margin-bottom: 22px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-16 {
    margin-bottom: 16px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-8 {
    margin-bottom: 8px;
}

.font-12 {
    font-size: 12px;
}
.font-14 {
    font-size: 14px;
}
.font-16 {
    font-size: 16px;
}
.font-20 {
    font-size: 20px;
}
.font-22 {
    font-size: 22px;
}
.font-24 {
    font-size: 24px;
}
.font-28 {
    font-size: 28px;
}
.font-30 {
    font-size: 30px;
}
.font-40 {
    font-size: 40px;
}
.font-42 {
    font-size: 42px;
}
.lh-24 {
    line-height: 24px;
}
.lh-20 {
    line-height: 20px;
}
.lh-18 {
    line-height: 18px;
}
.lh-16 {
    line-height: 16px;
}
.fwb {
    font-weight: bold;
}
.main-area {
    width: 1004px;
    margin: 0 auto;
}
.over-clamp-1 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.over-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.tac {
    text-align: center;
}
.c-title {
    color: #2B2B2B;
}
.c-normal {
    color: #282828;
}
.c-light {
    color: rgba(40, 40, 40, 0.70);
}
.c-desc {
    color: #535353;
}
.c-primary {
    color: #0019FF;
}
.center {
    margin: 0 auto;
}
.lh-30 {
    line-height: 30px;
}
.w-100 {
    width: 100%;
}
.slick-prev {
    z-index: 999 !important;
    transform: translate(140%, -110%) !important;
}
.slick-prev:before {
    content: "" !important;
}
.slick-next {
    transform: translate(-160%, -110%) !important;
}
.slick-next:before {
    content: "" !important;
}
.slick-active .custom-dot {
    background: rgba(0, 42, 239, 1);
}
.pointer {
    cursor: pointer;
}