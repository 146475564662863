@import '../../assets/base.css';
.carousel {
    height: 830px;
    top: 0;
    background: #fff;
}
.carousel-dot {
    justify-content: flex-start !important;
    top: 498px !important;
    width: 1160px !important;
    margin: 0 auto !important;
    padding: 0 10px !important;
    left: 0 !important;
}
.carousel-dot.slick-dots li button {
    background: rgba(61,98,252,0.4);;
}
.carousel-dot.slick-dots li.slick-active button {
    background: #0E60FF;
}
.carousel-img {
    height: 830px;
    width: 100%;
    object-fit: cover;
}
.carousel-box {
    height: 830px;
    width: 100%;
    position: absolute;
    top: 0;
    padding: 0 10px;
}
.carousel-info {
    width: 420px;
    margin-top: 190px;
}
.carousel-title {
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 6px;
}
.carousel-detail {
    width: 380px;
    margin-bottom: 36px;
    line-height: 24px;
}
.index_tag {
    background: rgba(0,25,255,0.06);
    border-radius: 4px;
    border: 1px solid rgba(0,25,255,0.6);
    color: #0019FF;
    font-size: 12px;
    line-height: 20px;
    padding: 0 8px;
    margin-right: 5px;
}
.chain {
    width: 1004px;
    height: 120px;
    position: absolute;
    transform: translateX(-50%);
    top: 536px;
    border-radius: 2.5px;
    border: 0.63px solid rgba(255,255,255,1);
    background: linear-gradient(180deg, rgba(243,245,248,1) 0%, rgba(255,255,255,1) 100%);
    box-shadow: 5px 5px 12.5px 0 rgba(55,99,170,0.1);
}
.chain-box {
    height: 100%;
    padding: 0 25px;
}
.chain-item {
    color: #000;
    flex: 1;
    justify-content: center;
    /* width: 201px; */
    /* margin-right: 25px; */
    border-right: 1px solid rgba(31,34,40,0.1);
    /* padding-right: 25px; */
}
.chain-item:first-child {
    justify-content: flex-start;
}
.chain-item:last-child {
    border: none;
    margin-right: 0;
}
.item-icon {
    width: 50px;
    height: 50px;
}
.item-label {
    margin-left: 6px;
}
.service {
    padding: 0 10px;
    padding-bottom: 120px;
    padding-top: 96px;
}
.block-list .block-item {
    text-align: center;
}
.block-item .block-icon {
    width: 70px;
}
.slide-style {
    background: #fff;
}
.slick-slider {
    position: relative;
}
.slick-list {
    overflow: hidden;
}
.slick-slider .slick-slide {
    float: left;
}
.slick-slider .slick-slide > div {
    margin: 0 10px;
}
.slide-item {
    position: relative;
    height: 384px;
    display: block;
    overflow: hidden;
}
.slide-item img {
    width: 1024px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
}
.item-content {
    width: 35%;
    position: absolute;
    left: 54%;
    top: 0;
}

.custom-dot {
    width: 24px;
    height: 4px;
    background: rgba(0, 42, 239, 0.08);
    margin-right: 5px;
}
.slick-active .custom-dot {
    background: rgba(0, 42, 239, 1);
}
.slick-dots li button:before {
    content: "" !important;
}
.pb120 {
    padding-bottom: 120px;
}
.slick-prev {
    z-index: 999 !important;
    transform: translate(140%, -110%) !important;
}
.slick-prev:before {
    content: "" !important;
}
.slick-next {
    transform: translate(-160%, -110%) !important;
}
.slick-next:before {
    content: "" !important;
}
.tab-list {
    width: 1004px;
    margin: 0 auto;
    background: #EFF3F8;
    height: 52px;
    box-shadow: inset 0px 1px 3px 0px rgba(55,99,170,0.2);
    border-radius: 25px;
    padding: 0 5px;
}
.tab-item {
    width: 25%;
    text-align: center;
    line-height: 52px;
    cursor: pointer;
}
.active-tab {
    height: 44px;
    background: #0019FE;
    box-shadow: 0px 0px 6px 1px rgba(0,25,254,0.3);
    border-radius: 25px;
    color: #fff;
    line-height: 44px;
}
.item-title {
    margin-top: 120px;
    line-height: 32px;
}
.btn-detail, .btn-blue {
    background: #0019FF;
    border-radius: 8px;
    border-color: #0019FF;
}
.btn-detail:hover, .btn-detail:focus, .btn-blue:hover, .btn-blue:focus {
    background: #0019FF;
    border-color: #0019FF;
    opacity: 0.8;
}