@import '../../assets/base.css';
.layout {
    background-color: #F5F7FA;
}
.mb-14 {
    margin-bottom: 14px;
}
.header {
    height: 64px;
    line-height: 64px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    border-bottom: 0.63px solid rgba(31,34,40,0.1);
    z-index: 999;
}
.header.ant-layout-header {
    padding: 0 10px;
}
.left {
    width: 576px;
    margin-right: 30px;
}
.divider-line {
    width: 1px;
    height: 210px;
    background-color: rgba(255, 255, 255, 0.3);
}
.middle {
    width: 220px;
    margin-left: 50px;
    color: #fff;
}
.master-style {
    width: 121px;
    margin-bottom: 25px;
}
.plat-icon {
    width: 20px;
}
.light-font {
    color: rgba(255, 255, 255, 0.8);
}
.right {
    color: #fff;
    /* margin-left: 108px; */
    width: 160px;
}
.addition {
    width: 189px;
    margin-right: 49px;
}
.logo-icon {
    width: 265px;
}
.logo-icon-head {
    width: 194px;
}
.logo-text {
    width: 100px;
    line-height: 20px;
    font-size: 20px;
    font-weight: 900;
    color: #0E60FF;
}

.nav-item {
    font-size: 16px;
    margin: 0 32px;
    white-space: nowrap;
    cursor: pointer;
}
.nav-selected {
    color: #0E60FF;
}
.content {
    /* padding-bottom: 155px; */
}
.footer {
    background-color: #242933;
    padding: 68px 10px;
    padding-top: 84px;
    position: relative;
}
.footer-area {
    margin-bottom: 72px;
}
.sign-text {
    color: rgba(255, 255, 255, 0.7);
}
.sign-part {
    width: 50%;
}
.mg-left {
    margin-left: 140px;
}
.border-r {
    border-right: 1px solid #fff;
}
.hover-text {
    opacity: 0.5;
}
.hover-text:hover {
    opacity: 1;
}
.icon-style {
    width: 18px;
    height: 20px;
}
.bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 70px;
    background: rgba(255, 255, 255, 0.1);
    left: 0;
}
.relative {
    position: relative;
}