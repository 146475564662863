@import '../../assets/base.css';

.top-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.product-head {
    padding: 0 10px;
    height: 300px;
    width: 100%;
    position: relative;
}
.product-head .info {
    width: 496px;
    top: 134px;
    position: absolute;
}
.product-head .title {
    margin-bottom: 6px;
    line-height: 50px;
}
.product-head .detail {
    opacity: 0.8;
    line-height: 28px;
}