@import '../../assets/base.css';

.card-style {
    width: 470px;
    border-radius: 4px;
    border: 1px solid rgba(255,255,255,1);
    background: linear-gradient(180deg, rgba(243,245,248,1) 0%, rgba(255,255,255,1) 100%);
    box-shadow: 8px 8px 20px 0 rgba(55,99,170,0.1);
    padding: 36px 32px;
    margin: 0 auto;
}
.card-style .year {
    line-height: 24px;
    height: 24px;
}
.card-style .name {
    margin-top: 16px;
    margin-bottom: 8px;
    opacity: 0.8;
}
.card-style .describe {
    opacity: 0.8;
}
.active-card {
    color: #FD6800;
}
.flag-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: top;
}
.year-text {
    display: inline-block;
    height: 24px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content {
    width: calc(48% - 12px);
}