@import '../../assets/base.css';

.process-bg .line-box {
    background-image: url("../../assets/earth.png");
    background-repeat: no-repeat;
    background-size: contain;
}
.map_module {
    width: 246px;
    height: 120px;
    border-radius: 8px;
    background: rgba(0, 42, 239, 0.1);
    margin-bottom: 22px;
}
.map_module:last-child {
    margin-bottom: 0;
}
.map_title {
    color: #002AEF;
    line-height: 45px;
}
.map_detail {
    color: #2B2B2B;
    line-height: 28px;
}
.about_us_container {
    position: relative;
}
.about_us_img {
    width: 100%;
    height: 500px;
    object-fit: cover;
}
.about_us_card {
    width: 1024px;
    height: 206px;
    background: linear-gradient(180deg, #F3F5F8 0%, #FFFFFF 100%);
    box-shadow: 8px 8px 20px 0px rgba(55,99,170,0.1);
    border-radius: 4px;
    opacity: 0.98;
    border: 1px solid #FFFFFF;
    position: absolute;
    top: 370px;
    transform: translateX(-50%);
}
.card_block {
    width: 340px;
    height: 138px;
    border-right: 1px solid rgba(31, 34, 40, 0.1);
}
.card_block:last-child {
    border-right: none;
}
.primary_font {
    font-size: 50px;
    font-weight: 800;
    color: #0019FE;
    line-height: 58px;
    margin-bottom: 16px;
}
.detail_font {
    font-size: 24px;
    font-weight: 400;
    color: #2B2B2B;
    line-height: 32px;
}
.quotation {
    width: 55px;
}