@import '../../assets/base.css';

.price-content .pain-card {
    width: 320px;
    height: 270px;
    box-shadow: 0px 2px 10px 7px rgba(0,38,92,0.08);
    border-radius: 10px;
    padding: 36px;
}
.price-content .pain-card .card-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 14px;
}
.price-content .pain-card .card-title {
    font-size: 20px;
    font-weight: bold;
    color: #2B2B2B;
    line-height: 28px;
    margin-bottom: 18px;
}
.price-content .pain-card .card-desc {
    color: #7E7E7E;
    line-height: 26px;
    font-size: 14px;
}
.solution-title {
    width: 422px;
    font-weight: bold;
    color: #2B2B2B;
    text-align: center;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 12px;
}
.price-content .transfer-box {
    width: 422px;
    height: 492px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 26px;
    padding: 16px;
}
.price-content .transfer-img {
    width: 160px;
    height: 351px;
}
.price-content .before-box {
    background: linear-gradient(90deg, rgba(119, 123, 147, 0.1), rgba(165, 168, 185, 0.1));
}
.price-content .after-box {
    background: linear-gradient(270deg, rgba(90, 176, 255, 0.1), rgba(0, 44, 249, 0.1));
}
.price-content .transfer-item {
    width: 390px;
    height: 148px;
    border-radius: 8px;
    padding: 10px 40px 28px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.price-content .before-item {
    background: rgba(209, 213, 223, 0.6);
    color: #535353;
}
.price-content .after-item {
    background: linear-gradient(270deg, rgba(33, 148, 255, 1), rgba(49, 86, 255, 1));
    color: #fff;
}
.price-content  .tranfer-text {
    margin-top: 12px;
}
.step-number {
    font-size: 120px;
    line-height: 120px;
    color: rgba(40, 40, 40, 0.2);
    font-family: Arial-BoldMT, Arial;
}
.price-content .fix-block {
    position: absolute;
    top: 90px;
    width: 100%;
}
.price-content .produce-bg {
    background-image: url("../../assets/produce-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 142px;
}
.slogen-style {
    width: 480px;
    height: 72px;
    background: linear-gradient(315deg, #2194FF 0%, #3156FF 100%);
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.24);
    border-radius: 36px;
    color: #fff;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    padding-left: 55px;
    margin-bottom: 20px;
}
.dot-style {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #fff;
    margin-right: 9px;
}
.value-text {
    color: #7E7E7E;
    line-height: 22px;
}
.w-160 {
    width: 155px;
}
.w-198 {
    width: 193px;
}
.w-438 {
    width: 433px;
}
.grid-title {
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    color: #fff;
    background: linear-gradient(315deg, #2194FF 0%, #3156FF 100%);
    border-radius: 4px;
    backdrop-filter: blur(3.691283086727784px);
}
.grid-value {
    background: rgba(255,255,255,0.3);
    border-radius: 4px;
    border: 1px solid;
    border-image: linear-gradient(90deg, rgba(119, 123, 147, 0.5), rgba(165, 168, 185, 0.5)) 1 1;
    backdrop-filter: blur(5px);
    padding: 16px 22px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.goal-title {
    color: #2B2B2B;
    line-height: 32px;
}
.middle-text {
    color: #535353;
    line-height: 32px;
    font-size: 18px;
}
.profit-title {
    font-weight: bold;
    color: #535353;
    line-height: 32px;
    font-size: 18px;
}
.profit-desc {
    color: #7E7E7E;
    line-height: 22px;
    font-size: 14px;
}
.price-content .border-bottom {
    border-bottom: 1px solid;
    border-image: linear-gradient(90deg, rgba(119, 123, 147, 0.5), rgba(165, 168, 185, 0.5)) 1 1;
}
.price-content .tool-box {
    padding-bottom: 46px;
    width: 340px;
    border-right: 1px solid;
    border-image: linear-gradient(90deg, rgba(119, 123, 147, 0.5), rgba(165, 168, 185, 0.5)) 1 1;
    padding-top: 16px;
}
.price-content .tool-box:last-child {
    border-right: none;
}