@import '../../assets/base.css';

.card-list {
    
}
.card {
    border-radius: 6px;
}
.card-large {
    padding: 44px 49px 52px 20px;
}
.card-large .card-text .title {
    color: #0E60FF;
    font-size: 38px;
    font-weight: 500;
    margin-bottom: 28px;
}
.card-large .card-text .detail {
    color: #888A8B;
    line-height: 40px;
    font-size: 20px;
}
.card-large .card-img {
    width: 144px;
    height: 144px;
}
.card-small {
    padding: 24px 28px;
}
.card-small .card-text .detail {
    font-weight: 500;
    line-height: 40px;
    font-size: 24px;
}
.card-small .card-img {
    width: 80px;
    height: 80px;
}