@import '../../assets/base.css';
.module-head {
    font-size: 32px;
    line-height: 32px;
    font-weight: 700;
    text-align: center;
}
.module-head .title{
    margin-bottom: 16px;
}
.module-head .bt-line {
    width: 32px;
    height: 4px;
    background-color: #0E60FF;
    margin: 0 auto;
}