@import '../../../assets/base.css';

.news-content {
    padding: 40px;
    margin-bottom: 80px;
}
.news-content .news-title {
    font-size: 28px;
    line-height: 44px;
    margin-bottom: 24px;
}
.news-content .border-b {
    border-bottom: 1px solid rgba(31,34,40,0.1);
}
.news-content .content-text {
    color: #333;
    line-height: 24px;
}
.pagination {
    padding-top: 32px;
}
.pagination .page .page-title {
    width: 208px;
    color: #246EFF;
}
.pagination .page .page-title:hover {
    font-weight: 700;
    text-decoration: underline;
}
.ant-breadcrumb a:hover {
    color: #246EFF !important;
}
.ant-breadcrumb a {
    color: #1F2228 !important;
}
.tit-1 {
    font-weight: bold;
    font-size: 14px;
    text-indent: 21pt;
}
.txt-1 {
    text-indent: 21pt;
    text-align: justify;
    font-size: 14px;
}