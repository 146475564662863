@import '../../assets/base.css';
.product-content {
    padding: 0 10px;
    padding-bottom: 80px;
    padding-top: 96px;
}
.bg-gradient-blue {
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(238,244,255,1) 100%);
}
.field-item {
    width: 374px;
    position: relative;
}
.field-item .field-img {
    width: 374px;
    height: 520px;
}
.field-item .mask {
    width: 374px;
    height: 520px;
    position: absolute;
    top: 0;
}

.field-item .blue-mask {
    background: linear-gradient(180deg, rgba(14,96,255,0) 0%, rgba(14,96,255,1) 100%);
}
.field-item .orange-mask {
    background: linear-gradient(180deg, rgba(253,104,0,0) 0%, rgba(253,104,0,1) 100%);
}
.field-content {
    position: absolute;
    top: 344px;
    margin: 0 24px;
    color: #fff;
}
.field-content .label {
    font-size: 36px;
    line-height: 36px;
    font-weight: 500;
}
.field-content .detail {
    line-height: 32px;
    margin-top: 24px;
}
.subtitle {
    font-size: 28px;
    font-weight: 500;
    color: #000;
    line-height: 32px;
}
.product-img {
    width: 100%;
    margin-bottom: 50px;
}
.process {
    margin-top: 40px;
}
.process-item {
    min-width: 170px;
}
.process-img {
    width: 64px;
    height: 64px;
    margin-right: 16px;
}