@import '../../assets/base.css';
.product-container {
    width: 500px;
    height: 374px;
    box-shadow: 0px 0px 6px 1px #D8D8D8;
    border-radius: 8px;
    /* position: absolute; */
    /* left: 600px;
    top: 56px; */
    z-index: 999;
    background: #fff;
}
.left-menu {
    width: 146px;
    border-right: 1px solid rgba(151, 151, 151, 0.2);
    color: #2B2B2B;
}
.left-item {
    height: 60px;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
}
.active-left-item {
    background: rgba(0, 25, 255, 0.06);
    color: #0019FF;
    font-weight: 500;
}
.right-menu {
    padding: 32px;
    width: 354px;
}
.right-item {
    /* height: 50px; */
    margin-bottom: 30px;
    cursor: pointer;
}
.menu-icon{
    width: 50px;
    height: 50px;
    margin-right: 8px;
}
.right-item-name {
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
}
.right-item-description {
    line-height: 16px;
    font-size: 12px;
    color: #7E7E7E;
}
.item-hover .right-item-name {
    color: #0019FF;
}