@import '../../assets/base.css';
.partner-content .point-card {
    width: 238px;
    height: 300px;
    box-shadow: 0px 2px 10px 7px rgba(0,38,92,0.08);
    border-radius: 10px;
    padding: 36px;
}
.partner-content .point-card .card-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 14px;
}
.partner-content .point-card .card-title {
    font-size: 20px;
    font-weight: bold;
    color: #2B2B2B;
    line-height: 28px;
    margin-bottom: 18px;
}
.partner-content .point-card .card-desc {
    color: #7E7E7E;
    line-height: 26px;
    font-size: 14px;
}
.solution-title {
    width: 422px;
    font-weight: bold;
    color: #2B2B2B;
    text-align: center;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 12px;
}
.partner-content .transfer-box {
    width: 422px;
    height: 778px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 26px;
    padding: 16px;
}
.partner-content .transfer-img {
    width: 160px;
    height: 160px;
}
.partner-content .before-box {
    background: linear-gradient(90deg, rgba(119, 123, 147, 0.1), rgba(165, 168, 185, 0.1));
}
.partner-content .after-box {
    background: linear-gradient(270deg, rgba(90, 176, 255, 0.1), rgba(0, 44, 249, 0.1));
}
.partner-content .transfer-item {
    width: 390px;
    height: 148px;
    border-radius: 8px;
    padding: 10px 40px 28px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.partner-content .transfer-item:nth-child(3) {
    height: 278px;
}
.partner-content .arrow-text {
    top: 68px;
    left: 48px
}
.partner-content .before-item {
    background: rgba(209, 213, 223, 0.6);
    color: #535353;
}
.partner-content .after-item {
    background: linear-gradient(270deg, rgba(33, 148, 255, 1), rgba(49, 86, 255, 1));
    color: #fff;
}
.partner-content .tranfer-text {
    margin-top: 12px;
}
.step-number {
    font-size: 120px;
    line-height: 120px;
    color: rgba(40, 40, 40, 0.2);
    font-family: Arial-BoldMT, Arial;
}
.partner-content .fix-block {
    position: absolute;
    top: 90px;
    width: 100%;
}
.partner-content .produce-bg {
    background-image: url("../../assets/produce-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 186px;
}
.value-text {
    color: #7E7E7E;
    line-height: 22px;
}
.w-160 {
    width: 155px;
}
.w-198 {
    width: 193px;
}
.w-438 {
    width: 433px;
}
.partner-content .grid-title {
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    color: #fff;
    background: linear-gradient(315deg, #2194FF 0%, #3156FF 100%);
    border-radius: 4px;
    backdrop-filter: blur(3.691283086727784px);
}
.partner-content .grid-value {
    background: rgba(255,255,255,0.3);
    border-radius: 4px;
    border: 1px solid;
    border-image: linear-gradient(90deg, rgba(119, 123, 147, 0.5), rgba(165, 168, 185, 0.5)) 1 1;
    backdrop-filter: blur(5px);
    padding: 16px 22px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.partner-content .goal-title {
    color: #2B2B2B;
    line-height: 32px;
}
.partner-content .middle-text {
    color: #535353;
    line-height: 32px;
    font-size: 18px;
}
.partner-content .profit-title {
    font-weight: bold;
    color: #535353;
    line-height: 32px;
    font-size: 18px;
}
.partner-content .profit-desc {
    color: #7E7E7E;
    line-height: 22px;
    font-size: 14px;
}
.partner-content .tool-box {
    width: 340px;
    border-right: 1px solid;
    border-image: linear-gradient(90deg, rgba(119, 123, 147, 0.5), rgba(165, 168, 185, 0.5)) 1 1;
    padding-bottom: 46px;
    padding-top: 16px;
    width: 328px;
}
.partner-content .tool-box:first-child {
    padding-right: 38px;
}
.partner-content .tool-box:nth-child(2) {
    padding-left: 38px;
    padding-right: 38px;
    width: 366px;
}
.partner-content .tool-box:last-child {
    border-right: none;
    padding-left: 38px;
}
.partner-content .border-bottom {
    border-bottom: 1px solid;
    border-image: linear-gradient(90deg, rgba(119, 123, 147, 0.5), rgba(165, 168, 185, 0.5)) 1 1;
}